@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.glow-shadow {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(23,29,51,0.2);
  -moz-box-shadow: 0px 0px 20px 0px rgba(23,29,51,0.2);
  box-shadow: 0px 0px 20px 0px rgba(23,29,51,0.2);
}

.negative-bottom {
  margin-bottom: -6.8rem
}

.negative-top {
  margin-top: -2.5rem
}